<template>
<section>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="mb-4">Página no encontrada</h1>
        <p>La dirección pudo haber cambiado desde la última vez que visitaste este sitio. Te recomendamos ir a la página de inicio e intentar con una nueva búsqueda.</p>
      </div>
    </div>
  </div>
  </section>
</template>
